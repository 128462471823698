import { useEffect, useState } from 'react';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { useSignupConversionTracking } from '../hooks/useSignupConversionTracking';
import { ANONYMOUS_ID } from '../helpers/tracking';
import { useCountry } from '../hooks/useCountry';

const SignUpSection = () => {
  const country = useCountry();

  const { t } = useTranslation();
  const { enableTracking } = useSignupConversionTracking();

  const [signUpURL, setSignUpURL] = useState(`${t('signup_section.form.address')}`);

  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);

  useEffect(() => {
    const searchParams = new URL(location.href).searchParams;
    const anonymousId = localStorage?.getItem(ANONYMOUS_ID);

    if (anonymousId) {
      const parsedId = JSON.parse(anonymousId);
      searchParams.set('utm_content', parsedId);
    }

    let queryString = searchParams.toString();

    // if querystring includes REFERRALCODE replace it with utm_term
    if (queryString.includes('REFERRALCODE')) {
      queryString = queryString.replace('REFERRALCODE', 'utm_term');
    }

    if (queryString) {
      const url = `${t('signup_section.form.address')}${queryString}`;
      if (signUpURL === url) return;
      setSignUpURL(url);
    }
  }, [location, signUpURL, t]);

  const renderRiderModal = () => {
    return (
      <Modal isOpen={isSignUpModalVisible} onCancel={() => setIsSignUpModalVisible(false)}>
        <div className="flex h-600 flex-col items-start overflow-auto bg-white px-6 pb-12 pt-16 text-left text-blue-prussian">
          {/* <h2 className="text-lg">{t('signup_section.modal.title')}</h2> */}
          <div className="mt-2">
            <div
              className="pt-4 font-flink-regular text-sm"
              dangerouslySetInnerHTML={{ __html: t('signup_section.modal.description') }}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <section
      id="apply_now"
      className="bg-flinkRose-light py-16 px-5 text-center font-flink-light text-pink-deep"
    >
      <div className="signup-section-animated mx-auto max-w-6xl shadow-lg shadow-rose-500/50 drop-shadow-lg">
        <div className="mx-auto bg-white">
          <div className="w-full">
            <div className="mx-auto max-w-2xl pt-10 lg:text-5xl">
              <h3
                onClick={() => {
                  country === 'nl' ? setIsSignUpModalVisible(true) : null;
                }}
                className={`max-w-2xl px-4 text-4xl md:text-5xl ${
                  country === 'nl' ? 'cursor-pointer underline decoration-2 underline-offset-4' : ''
                }`}
              >
                {t('signup_section.title')}
              </h3>
            </div>
            <div className="">
              <iframe
                data-cy="signup-iframe"
                src={signUpURL}
                width="100"
                height="100"
                scrolling="no"
                className="h-[1800px] w-full truncate"
                onLoad={enableTracking}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {renderRiderModal()}
    </section>
  );
};

export default SignUpSection;
